import { BaseDto } from '@/shared/dtos/base-dto';

export class registros_probar_app extends BaseDto {
    public nombre !: string;
    public apellidos !: string;
    public empresa !: string;
    public telefono !: string;
    public email !: string;
    public contrasena !: string;
    public token_verificacion !: string;
    public ip !: string;
    public confirmado !: boolean;

} 
