import { store } from '@/store/store';
import { registros_probar_app } from '@/shared/dtos/admin/registros_probar_app';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'registros_probar_appModule',
    store,
    dynamic: true
})
class registros_probar_appModule extends VuexModule {
    public registros_probar_apps: registros_probar_app[] = [];
    public registros_probar_app: registros_probar_app = new registros_probar_app();

    @Action({ commit: 'onGetregistros_probar_apps' })
    public async getregistros_probar_apps() {
        return await ssmHttpService.get(API.registros_probar_app);
    }

    @Action({ commit: 'onGetregistros_probar_app' })
    public async getregistros_probar_app(id: any) {
        return await ssmHttpService.get(API.registros_probar_app + '/' + id);
    }
    @Action({ commit: 'onGetregistros_probar_app' })
    public async getSendConfirmarCuenta(id: any) {
        return await ssmHttpService.get(API.registros_probar_app + '/SendConfirmarCuenta/' + id);
    }
    @Action({ })
    public async getCreateOdooUser(id: any) {
        return await ssmHttpService.get(API.registros_probar_app + '/create_odoo_user/' + id);
    }
    
    

    @Action
    public async guardarregistros_probar_app(registros_probar_app: registros_probar_app) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.registros_probar_app, registros_probar_app.toJson());
        this.getregistros_probar_apps();
    }

    @Action
    public async modificarregistros_probar_app(registros_probar_app: registros_probar_app) {
        await ssmHttpService.put(API.registros_probar_app, registros_probar_app);
        this.getregistros_probar_apps();
    }

    @Action
    public async eliminarregistros_probar_app(registros_probar_app: registros_probar_app) {
        await ssmHttpService.delete(API.registros_probar_app + '/' + registros_probar_app.id, null, false);
        this.getregistros_probar_apps();
    }

    @Mutation
    public onGetregistros_probar_apps(res: registros_probar_app[]) {

        this.registros_probar_apps = res ? res.map((x) => new registros_probar_app(x)) : []
    }

    @Mutation
    public onGetregistros_probar_app(res: registros_probar_app) {
        this.registros_probar_app = new registros_probar_app(res);
    }

}
export default getModule(registros_probar_appModule);