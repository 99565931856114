




































import { registros_probar_app } from "@/shared/dtos/admin/registros_probar_app";
import registros_probar_appModule from "@/store/modules/Admin/registros_probar_app-module";
import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import { Component, Vue } from "vue-property-decorator";
import { buttonOptions } from "@/components/Buttons/buttonOptions";
@Component({
  components: {
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue"),
  },
})
export default class registros_probar_appLista extends Vue {
  //Se ejecuta nada mas entrar
  public show_dialog_more_options: boolean = false;
  public elemento: registros_probar_app = new registros_probar_app();

  public created() {
    this.Refresh();
  }

  public get DataSource() {
    return registros_probar_appModule.registros_probar_apps;
  }

  public get Columns() {
    var Columnas: ClassColumnDataTable[] = [];
    Columnas.push(
      new ClassColumnDataTable(
        "Id",
        "id",
        datatypes.number,
        false,
        datatypes.idlength
      )
    );
    let Columnaconfirmado: ClassColumnDataTable = new ClassColumnDataTable(
      "confirmado",
      "confirmado",
      datatypes.boolean,
      true,
      datatypes.maxlength
    );
    Columnas.push(Columnaconfirmado);
    let Columnanombre: ClassColumnDataTable = new ClassColumnDataTable(
      "nombre",
      "nombre",
      datatypes.string,
      true,
      datatypes.maxlength
    );
    Columnas.push(Columnanombre);
    let Columnaempresa: ClassColumnDataTable = new ClassColumnDataTable(
      "empresa",
      "empresa",
      datatypes.string,
      true,
      datatypes.maxlength
    );
    Columnas.push(Columnaempresa);
    let Columnatelefono: ClassColumnDataTable = new ClassColumnDataTable(
      "telefono",
      "telefono",
      datatypes.string,
      true,
      datatypes.maxlength
    );
    Columnas.push(Columnatelefono);
    let Columnaemail: ClassColumnDataTable = new ClassColumnDataTable(
      "email",
      "email",
      datatypes.string,
      true,
      datatypes.maxlength
    );
    Columnas.push(Columnaemail);
    let Columnacontrasena: ClassColumnDataTable = new ClassColumnDataTable(
      "contrasena",
      "contrasena",
      datatypes.string,
      true,
      datatypes.maxlength
    );
    Columnas.push(Columnacontrasena);
    let Columnatoken_verificacion: ClassColumnDataTable = new ClassColumnDataTable(
      "token_verificacion",
      "token_verificacion",
      datatypes.string,
      true,
      datatypes.maxlength
    );
    Columnas.push(Columnatoken_verificacion);
    let Columnaip: ClassColumnDataTable = new ClassColumnDataTable(
      "ip",
      "ip",
      datatypes.string,
      true,
      datatypes.maxlength
    );
    Columnas.push(Columnaip);

    return Columnas;
  }

  public async MoreOptions(id: any) {
    if (id <= 0) {
      return;
    }
    let ss = this.DataSource.find((x) => x.id === id);
    if (ss !== undefined) {
      this.elemento = ss;
    }
    this.show_dialog_more_options = true;
  }

  public get Opciones_a_realizar() {
    let opts: buttonOptions[] = [];
    opts.push(
      new buttonOptions({
        id: 1,
        title: "Enviar correo de confirmación",
        EventMethod: this.EnvCorrConf,
      })
    );
    opts.push(
      new buttonOptions({
        id: 2,
        title: "Ver link confirmación",
        EventMethod: this.cplnkconf,
      })
    );
    opts.push(
      new buttonOptions({
        id: 3,
        title: "Confirmar link confirmación",
        EventMethod: this.cplnkconf,
      })
    );
    opts.push(
      new buttonOptions({
        id: 4,
        title: "Create Odoo User",
        EventMethod: this.crOdUs,
      })
    );
    opts.push(
      new buttonOptions({
        id: 5,
        title: "Eliminar registro",
        EventMethod: this.eliminar,
      })
    );
    return opts;
  }
  public eliminar() {
    this.show_dialog_more_options = false;
    registros_probar_appModule
      .eliminarregistros_probar_app(this.elemento)
      .then(() => this.Refresh());
  }

  public crOdUs() {
    this.show_dialog_more_options = false;
    registros_probar_appModule.getCreateOdooUser(this.elemento.id);
  }
  public EnvCorrConf() {
    this.show_dialog_more_options = false;
    registros_probar_appModule.getSendConfirmarCuenta(this.elemento.id);
  }
  public confirmar_link() {
    window.open(
      "https://app.mymedicaldiet.es/#/validar-cuenta/" +
        this.elemento.token_verificacion
    );
    this.show_dialog_more_options = false;
  }
  public cplnkconf() {
    this.show_dialog_more_options = false;
    /* Get the text field */
    var copyText = document.getElementById("myInput")!;
    //@ts-ignore
    copyText.value =
      "https://app.mymedicaldiet.es/#/validar-cuenta/" +
      this.elemento.token_verificacion;
    /* Select the text field */
    //@ts-ignore
    copyText.select();
    //@ts-ignore
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    try {
      var successful = document.execCommand("copy");
    } catch (err) {
      alert("Oops, unable to copy");
    }
  }

  public Refresh() {
    registros_probar_appModule.getregistros_probar_apps();
  }
}
